//Typography


h1{
  font: 26px/1.1em $PoppinsBold;
  color: $nu-purple;
  margin-bottom: 5px;
  margin-top: 0;
}

h2 {
    font: 18px/40px $AkkuratProBold;
    text-transform: uppercase;
    color: #4e2a84;
    margin: 0px;
}

h3 {
    font: 18px $PoppinsBold;
    color: $nu-purple;
    line-height: 1.1em;
    margin: 0;
}

.small-gray-italic {
  font: 12px/1.2em $AkkuratLightItalic;
  font-weight : normal;
  color: $rich-black-20;
}


p{
  font: 16px/1.2em $AkkuratProRegular;
  color: $rich-black-80;
}

a{
  color: $nu-purple;
  &:hover{
    text-decoration: none;
    color: $nu-purple-30;
  }
}



.key{
  font-family: $AkkuratProBold;
  color: $rich-black-80;
  line-height: 1.2em;
}

.value{
  font-family: $AkkuratProRegular;
  color: $rich-black-80;
  line-height: 1.2em;
}




// web fonts
@font-face {
    font-family: "Akkurat Pro Light";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Light Italic";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProLightItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Regular";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProRegular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Italic";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Bold";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Bold Italic";
    src: url("https://common.northwestern.edu/v8/css/fonts/AkkuratProBoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "Poppins Light";
  src: url("https://common.northwestern.edu/v8/css/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold";
  src: url("https://common.northwestern.edu/v8/css/fonts/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Extra Bold";
  src: url("https://common.northwestern.edu/v8/css/fonts/Poppins-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Extra Light";
  src: url("https://common.northwestern.edu/v8/css/fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
    font-family: "Ionicons";
    src: url("#{$path-fonts}/ionicons.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
