// Heros
.hero {
    width: 100%;
    display: table;

    .hero-image {
        text-align: center;
        display: table-cell;
        width: 100%;
        vertical-align: middle;
        position: relative;
        padding: 0 1rem;

        &:before {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.45)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%);
        }
    }

    h2 {
        font: 24px $PoppinsExtraBold;
        text-transform: none;
        color: $white;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
        margin-bottom: 1.5em;
        position: relative;

        &:before {
            background: url("#{$path-images}/ltpurple-slash.svg") no-repeat 50% / 17px 21px;
            padding: 0 1.5rem;
            content: " ";
        }

        &:after {
            background: url("#{$path-images}/ltpurple-slash.svg") no-repeat 50% / 17px 21px;
            padding: 0 1.5rem;
            content: " ";
        }
    }

    p {
        font: 60px/1.2em $PoppinsBold;
        color: $white;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
        margin: 0 0 1rem 0;
        position: relative;
    }

}



// override for top level pages that use smaller heroes
.small-hero {
    .hero-image {
        height: 420px;
    }
}

@media screen and (max-width: 1000px) {
  .hero {
    p {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-image {
    &.big-hero-image {
      height: 350px;
    }
  }
}

@media screen and (max-width: 480px) {
  .hero {
    .hero-image {
      height: 400px;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 1rem;

      &:before {
        background: none;
        padding: 0;
      }

      &:after {
        background: none;
        padding: 0;
      }
    }

    p {
      font-size: 28px;
    }
  }
}


// Section Top

section {
  margin-bottom: 10rem;
  ul{
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      list-style-type: none;
      a{
        @extend .gm-button;
        &:focus{
          color: white;
          text-decoration: none;
        }
        @media all and (max-width: 400px){
          width: 100%;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .center-list {
    list-style: none;
    margin: 2rem 0 0 0;
    padding: 0;
    text-align: center;
  }
}

// section top wrapper for non-hero and non-background-select areas
.section-top {
  margin-bottom: 3rem;
  text-align: center;
  h3 {
    font: 24px "Poppins Extra Bold",Impact,sans-serif;
    color: #4e2a84;
    text-align: center;
    margin-bottom: 4rem;
    &:before {
      background: url("#{$path-images}/grey-slash.svg") no-repeat 50%/17px 21px;
      padding: 0 20px;
      content: " ";
    }
    &:after{
      background: url("#{$path-images}/grey-slash.svg") no-repeat 50%/17px 21px;
      padding: 0 20px;
      content: " ";
    }
    &+p {
      margin-top: -2rem;
    }
  }
  p.subhead{
    color: #4e2a84;
    font: 46px/1.2em "Poppins Extra Light",Tahoma,sans-serif;
    margin-bottom: 2rem;
  }
  .center-list{
    margin-top: 2rem;
  }
}

@media screen and (max-width: 480px) {

  section {
    margin-bottom: 2.5rem;
  }

  .section-top {
    h3 {
      font: 20px $PoppinsExtraBold;
      margin-bottom: 1rem;
      &:before {
        background: none;
        padding: 0;
      }
      &:after {
        background: none;
        padding: 0;
      }
      &+p {
        margin-top: 0;
      }
    }
    p.subhead {
      font-size: 28px;
    }
  }
}

// Stats Call Out

// stats callout



.stats-callout {
  border-top: 4px solid $light-grey;
  border-bottom: 4px solid $light-grey;
  text-align: center;
  padding: 2rem 0;
  @include clearfix;
  div {
    width: 25%;
    float: left;
    padding: 1rem;
  }

  ul {
    list-style: none;

    li {
      text-align: left;
      padding-bottom: 0;
    }
  }

  p {
    margin: 0 auto;
    display: table;
  }

  span {
    display: table-cell;
    vertical-align: middle;

    &.big {
      font: 72px/1.1em $PoppinsBold;
      color: $nu-purple;
      display: block;
    }

    &.small {
      font: 24px $PoppinsLight;
      color: $nu-purple;
      display: block;
    }
  }

  &.one-callout {

    span {

      &.big {
        display: inline;
      }

      &.small {
        display: inline;
        padding-left: 1rem;
      }
    }
  }
}

// media queries
@media screen and (max-width: 800px) {

  .stats-callout {

    span {
      &.big {
        font: 48px $PoppinsBold;
      }

      &.small {
        font: 20px $PoppinsLight;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .stats-callout {
    span {
      &.big {
        font: 24px $PoppinsBold;
      }
      &.small {
        font: 15px $PoppinsLight;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .stats-callout { // for one callout
    &.one-callout {
      span {
        &.big, &.small  {
          display: block;
        }
      }
    }
  }
}


// Quotes

.quote {
  width: 50%;
  float: left;
  padding: 20px;
  @media all and (max-width: 768px){
    float: none;
    width: 100%;
    margin: 25px 0px;
    padding: 0px;
  }
  p{
    font: 24px/1.2em $PoppinsLight;
    color: #4e2a84;
    margin-bottom: .5em;
  }
  span {
    background: url("#{$path-images}/quote.png") no-repeat;
    width: 35px;
    height: 24px;
    display: inline-block;
    margin-right: .5rem;
  }
  figcaption {
    p{
      font: 16px/1.4em $AkkuratProRegular;
      color: #716c6b;
      strong{
        font-family: $AkkuratProBold;
      }
    }
  }
}


// Skrollr Sections

.skrollr_section{
  .contain-970{
    @include clearfix;
  }
  .left,
  .right{
    width: 50%;
    padding: 10px;
    float: left;
  }
  @media all and (max-width: 768px){
    .left,
    .right{
      width: 100%;
      padding: 0;
      float: none;
      &.images{
        display: none !important;
      }
    }
  }
}

.skrollr_section{
  .images{
    display: flex;
    justify-content: space-between;
    position: relative;
    .obj{
      position: relative;
    }
  }
}

#big_picture{
  .images{
    .obj{
      top: 50%;
      width: 25%;
      height: 100px;
      &.image1{
        background: url("#{$path-images}/skrollr/1.png") no-repeat;
        background-size: contain;
      }
      &.image2{
        background: url("#{$path-images}/skrollr/2.png") no-repeat;
        background-size: contain;
      }
      &.image3{
        background: url("#{$path-images}/skrollr/3.png") no-repeat;
        background-size: contain;
      }
      &.image4{
        background: url("#{$path-images}/skrollr/4.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

#manage_people{
  .images{
    .obj{
      top: 0;
      width: 100px;
      height: 147.3px;
      &.image1{
        background: url("#{$path-images}/RD_Icons_peopletab.svg") no-repeat;
        background-size: contain;
        left: 40%;
      }
    }
  }
}

#save_time{
  .images{
    .obj{
      top: 0;
      width: 100px;
      height: 147.3px;
      &.image1{
        background: url("#{$path-images}/skrollr/smartwatch.png") no-repeat;
        background-size: 100px 147.3px;
        left: 40%;
      }
    }
  }
}

#design{
  .images{
    @include clearfix;
    display: flex;
    justify-content: space-between;
    .obj{
      &.image1{
        align-self: flex-end;
        width: 159px;
        left: 5%;
        flex-shrink: 0;
        height: 149.9px;
        background: url("#{$path-images}/skrollr/computer.png") no-repeat;
        background-size: 159px 149.9px;
      }
      &.image2{
        align-self: flex-end;
        width: 100px;
        flex-shrink: 0;
        left: 5%;
        height: 90.8px;
        background: url("#{$path-images}/skrollr/laptop.png") no-repeat;
        background-size: 100px 90.8px;
      }
      &.image3{
        align-self: flex-end;
        width: 50px;
              left: 5%;
        flex-shrink: 0;
        height: 78.6px;
        background: url("#{$path-images}/skrollr/mobilephone.png") no-repeat;
        background-size: 50px 78.6px;

      }
    }
  }
}



.hero-image{
	background-image: url("#{$path-images}/skrollr/home_hero.jpg");
	background-position: center;
	background-size: cover;
}

.skrollr_login{
	margin-top: 5rem;
}
@media all and (min-width:768px){
  .skrollr_login{
  	margin-top: 5rem;
  	font-size: 16px;
  	padding: 16px;
  	padding-left: 66px;
  	padding-right: 26px;
  	&:after{
  		width: 44px;
  		background-size: 30px;
  	}
  }
}
